// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"

import ClientOption from "./components/ClientOption"

const ClientOptions = ({ clientOptions }) => {
  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        mb: ["64px", "64px", "128px"],
      }}
    >
      {clientOptions.map((clientOption, i) => (
        <ClientOption key={i} clientOption={clientOption} />
      ))}
    </div>
  )
}

export default ClientOptions
