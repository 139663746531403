// eslint-disable-next-line no-unused-vars
import React, { useState } from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"

import { fontWeights } from "../../utils/theme/font-weights"

import ActiveTestimonial from "./components/ActiveTestimonial"

const CLientTestimonials = ({ clientTestimonials }) => {
  const numberOfTestimonials = clientTestimonials.length
  const dotsNavigation =
    numberOfTestimonials > 1 ? Array(numberOfTestimonials).fill("•") : null

  const [activeTestimonial, setActiveTestimonial] = useState(
    clientTestimonials[0]
  )

  const indexOfCurrentActiveEl = clientTestimonials.indexOf(activeTestimonial)

  const handleNextTestimonial = () => {
    if (activeTestimonial === clientTestimonials[numberOfTestimonials - 1]) {
      return setActiveTestimonial(clientTestimonials[0])
    } else {
      return setActiveTestimonial(
        clientTestimonials[indexOfCurrentActiveEl + 1]
      )
    }
  }

  const handlePreviousTestimonial = () => {
    if (activeTestimonial === clientTestimonials[0]) {
      return setActiveTestimonial(clientTestimonials[numberOfTestimonials - 1])
    } else {
      return setActiveTestimonial(
        clientTestimonials[indexOfCurrentActiveEl - 1]
      )
    }
  }

  const handleDotsNavigation = index => {
    return setActiveTestimonial(clientTestimonials[index])
  }

  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FFF9F0",
        borderRadius: "8px",
        alignItems: "center",
        px: ["16px", "32px", "64px"],
        pb: "32px",
        mb: ["64px", "64px", "128px"],
      }}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div
          sx={{
            display: numberOfTestimonials === 1 ? "none" : "inherit",
            fontSize: "21px",
            color: "#FFA41B",
            cursor: "pointer",
          }}
          onClick={handlePreviousTestimonial}
        >
          ᐸ
        </div>
        <ActiveTestimonial activeTestimonial={activeTestimonial} />
        <div
          sx={{
            display: numberOfTestimonials === 1 ? "none" : "inherit",
            fontSize: "21px",
            color: "#FFA41B",
            cursor: "pointer",
          }}
          onClick={handleNextTestimonial}
        >
          ᐳ
        </div>
      </div>
      <div>
        {dotsNavigation.map((dot, i) => (
          <span
            key={i}
            sx={{
              color: "#FFA41B",
              opacity: i === indexOfCurrentActiveEl ? 1 : 0.1,
              fontSize: "20px",
              fontWeight: fontWeights.black,
              cursor: "pointer",
              ml: i !== 0 ? "16px" : "0",
            }}
            onClick={() => handleDotsNavigation(i)}
          >
            {dot}
          </span>
        ))}
      </div>
    </div>
  )
}

export default CLientTestimonials
