// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"

import { fontWeights } from "../../utils/theme/font-weights"
import whatWeDo from "../../content/product/whatWeDo.json"

const WhatWeDo = ({ langKey }) => {
  const whatWeDoQueryData = useStaticQuery(graphql`
    query WhatWeDoQuery {
      whatWeDoBgImg: file(relativePath: { eq: "ServicesHeroBG.png" }) {
        childImageSharp {
          fluid(maxWidth: 1632) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      flags: file(relativePath: { eq: "Flags.png" }) {
        childImageSharp {
          fluid(maxWidth: 1060) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      marketplaces: file(relativePath: { eq: "Marketplaces.png" }) {
        childImageSharp {
          fluid(maxWidth: 1110) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const { whatWeDoBgImg, flags, marketplaces } = whatWeDoQueryData

  return (
    <BackgroundImage
      fluid={whatWeDoBgImg.childImageSharp.fluid}
      sx={{ mb: ["64px", "64px", "128px"], py: "32px" }}
    >
      <div
        sx={{
          maxWidth: "1110px",
          textAlign: "center",
          mx: "auto",
        }}
      >
        <p
          sx={{
            color: "#100720",
            fontSize: ["22px", "32px", "36px"],
            fontWeight: fontWeights.extraBold,
            mb: "32px",
          }}
        >
          {langKey === "fr" ? whatWeDo.fr.title : whatWeDo.en.title}
        </p>
        <Img fluid={flags.childImageSharp.fluid} sx={{ mb: "32px" }} />
        <Img fluid={marketplaces.childImageSharp.fluid} />
      </div>
    </BackgroundImage>
  )
}

export default WhatWeDo
