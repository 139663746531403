// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"

import { fontWeights } from "../../utils/theme/font-weights"
import { colors } from "../../utils/theme/colors"

import ContactRedirection from "../contact-redirection"

const ContactSection = ({ langKey }) => {
  return (
    <div
      sx={{
        display: "flex",
        flexDirection: ["column", "column", "column"],
        justifyContent: "space-evenly",
        alignItems: "center",
        mb: ["64px", "128px", "128px"],
      }}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: ["100%", "100%", "23%"],
          textAlign: ["center", "center", "center"],
        }}
      >
        <p
          sx={{
            color: "#100720",
            fontSize: ["22px", "32px", "48px"],
            fontWeight: fontWeights.extraBold,
            mb: "16px",
          }}
        >
          Have an inquiry for us?
        </p>
        <p
          sx={{
            color: "#100720",
            fontSize: ["15px", "18px", "18px"],
            fontWeight: fontWeights.black,
          }}
        >
          We’d love to hear from you.
        </p>
        <ContactRedirection
          href={
            langKey === "en"
              ? "https://www.jellyfish.com/en-us/contact-us"
              : "https://www.jellyfish.com/fr-fr/contact-us"
          }
          buttonContent={"Book Demo"}
          overrideCSS={{ width: "fit-content" }}
        />
      </div>
    </div>
  )
}

export default ContactSection
