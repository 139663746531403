// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import Img from "gatsby-image"

import { fontWeights } from "../../../utils/theme/font-weights"

const AmazonToolboxGridItem = ({ toolboxItem }) => {
  const { toolkitIcon, toolkitName, toolkitDesscription } = toolboxItem

  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        maxWidth: ["100%", "400px", "415px"],
      }}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: ["40px", "50px", "70px"],
          backgroundColor: "#FFFAF1",
          borderRadius: "10px",
          padding: "20px 25px",
          mb: "16px",
        }}
      >
        <Img
          fluid={toolkitIcon.fluid}
          sx={{ width: ["16px", "20px", "32px"] }}
        />
      </div>
      <p
        sx={{
          color: "#100720",
          fontSize: ["18px", "18px", "28px"],
          fontWeight: fontWeights.extraBold,
          mb: "16px",
        }}
      >
        {toolkitName}
      </p>
      <p
        sx={{
          color: "#100720",
          opacity: 0.9,
          fontSize: ["15px", "15px", "18px"],
          fontWeight: fontWeights.body,
          mb: "0",
        }}
      >
        {toolkitDesscription.toolkitDesscription}
      </p>
    </div>
  )
}

export default AmazonToolboxGridItem
