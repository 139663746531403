// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import Img from "gatsby-image"

import { fontWeights } from "../../utils/theme/font-weights"
import { colors } from "../../utils/theme/colors"
import ourClients from "../../content/product/ourClients.json"

import ContactRedirection from "../contact-redirection"

const OurClients = ({ langKey, clientLogos }) => {
  return (
    <div
      sx={{
        display: "flex",
        flexDirection: ["column", "column", "row"],
        justifyContent: "space-between",
        alignItems: "center",
        mb: "128px",
      }}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          width: ["100%", "100%", "33%"],
          mr: ["0", "0", "80px"],
        }}
      >
        <p
          sx={{
            color: "#100720",
            fontSize: ["22px", "32px", "32px"],
            textAlign: ["center", "center", "left"],
            fontWeight: fontWeights.extraBold,
            mb: "32px",
          }}
        >
          {langKey === "fr" ? ourClients.fr.title : ourClients.en.title}
        </p>
        <ContactRedirection
          href={
            langKey === "en"
              ? "https://www.jellyfish.com/en-us/contact-us"
              : "https://www.jellyfish.com/fr-fr/contact-us"
          }
          buttonContent={"Book Demo"}
          overrideCSS={{
            width: "fit-content",
            display: ["none", "none", "inherit"],
            fontSize: ["12px", "14px", "16px"],
            fontWeight: fontWeights.bold,
            textTransform: "uppercase",
            px: "20px",
            alignSelf: "flex-start",
          }}
        />
      </div>
      <div
        sx={{
          display: ["none", "none", "grid"],
          gridTemplateColumns: "1fr 1fr 1fr",
          gap: "50px",
          mb: ["64px", "64px", "0"],
        }}
      >
        {clientLogos.map((clientImage, i) => (
          <Img key={i} fixed={clientImage.fixed} sx={{ mx: "auto" }} />
        ))}
      </div>
    </div>
  )
}

export default OurClients
