// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"

import { fontWeights } from "../../utils/theme/font-weights"

import AmazonToolboxGridItem from "./components/AmazonToolboxItem"

const AmazonToolbox = ({ seelksAmazonToolbox }) => {
  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyCOntent: "center",
        alignItems: "center",
        mb: "128px",
      }}
    >
      <p
        sx={{
          colors: "#100720",
          fontSize: ["22px", "32px", "48px"],
          fontWeight: fontWeights.extraBold,
          textAlign: "center",
          mb: ["64px", "64px", "128px"],
        }}
      >
        Seelk’s Amazon toolbox
      </p>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: ["1fr", "1fr 1fr", "1fr 1fr 1fr"],
          columnGap: "190px",
          rowGap: "64px",
        }}
      >
        {seelksAmazonToolbox.map((toolboxItem, i) => (
          <AmazonToolboxGridItem key={i} toolboxItem={toolboxItem} />
        ))}
      </div>
    </div>
  )
}

export default AmazonToolbox
