// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { colors } from "../utils/theme/colors"
import { fontWeights } from "../utils/theme/font-weights"

const ContactRedirection = ({ buttonContent, href, overrideCSS }) => (
  <a
    href={href}
    sx={{
      px: "10px",
      height: `3.3rem`,
      alignSelf: [null, null, `center`, `center`],
      color: colors.white,
      backgroundColor: colors.code.primary,
      borderRadius: `5px`,
      fontSize: `0.99rem`,
      fontWeight: fontWeights.semiBold,
      letterSpacing: `1.6px`,
      outline: `none`,
      textDecoration: "none",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      ":hover": {
        background: `#F58D00 0% 0% no-repeat`,
        boxShadow: `0px 1px 20px #1F1F1F33`,
        borderRadius: `6px`,
        opacity: 1,
        cursor: `pointer`,
      },
      ...overrideCSS,
    }}
  >
    {buttonContent}
  </a>
)

export default ContactRedirection
