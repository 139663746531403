// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import Img from "gatsby-image"

import { fontWeights } from "../../../utils/theme/font-weights"
import { colors } from "../../../utils/theme/colors"

const ClientOption = ({ clientOption }) => {
  const {
    clientOptionColor,
    clientOptionIcon,
    clientOptionName,
    clientOptionTitle,
    clientOptionSubtitle,
    clientOptionImage,
  } = clientOption

  return (
    <div
      sx={{
        display: "flex",
        flexDirection: ["column", "column", "row"],
        justifyContent: ["center", "center", "space-evenly"],
        alignItems: "center",
        backgroundColor: `${clientOptionColor}1A`,
        borderRadius: "8px",
        width: "100%",
        mx: "auto",
        py: ["64px", "64px", "165px"],
      }}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          width: ["78%", "78%", "33%"],
          maxWidth: [null, "524px", null],
        }}
      >
        <div
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            mb: "8px",
          }}
        >
          <div
            sx={{
              backgroundColor: clientOptionColor,
              padding: ["5px", "7px", "10px"],
              borderRadius: "3px",
              mr: "8px",
            }}
          >
            <Img
              fluid={clientOptionIcon.fluid}
              sx={{
                width: ["10px", "15px", "20px"],
                colors: colors.white,
              }}
            />
          </div>
          <p
            sx={{
              color: clientOptionColor,
              fontSize: ["14px", "14px", "18px"],
              fontWeight: fontWeights.bold,
              letterSpacing: 0.9,
              textTransform: "uppercase",
              mb: "0",
            }}
          >
            {clientOptionName}
          </p>
        </div>
        <p
          sx={{
            color: "#100720",
            fontSize: ["18px", "22px", "32px"],
            fontWeight: fontWeights.extraBold,
            mb: ["16px", "16px", "32px"],
          }}
        >
          {clientOptionTitle}
        </p>
        <p
          sx={{
            color: "#100720",
            fontSize: ["15px", "18px", "24px"],
            fontWeight: fontWeights.normal,
            mb: "32px",
          }}
        >
          {clientOptionSubtitle}
        </p>
      </div>
      <div
        sx={{
          position: "relative",
          width: ["100%", "100%", "44%"],
          perspective: [null, null, "500px"],
        }}
      >
        <Img
          fluid={clientOptionImage.fluid}
          sx={{
            position: "absolute",
            maxWidth: ["78%", "78%", "745px"],
            transformStyle: "preserve-3d",
            left: ["50%", "50%", "0"],
            transform: [
              "translateX(-50%)",
              "translateX(-50%)",
              "rotateY(-10deg)",
            ],
          }}
        />
      </div>
    </div>
  )
}

export default ClientOption
