// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import Container from "../components/layout/container"
import ProductHero from "../components/product/Hero"
import ClientTestimonials from "../components/product/ClientTestimonials"
import WhatWeDo from "../components/product/WhatWeDo"
import ClientOptions from "../components/product/ClientOptions"
import OurClients from "../components/product/OurClients"
import AmazonToolbox from "../components/product/AmazonToolbox"
import Strategies from "../components/product/Strategies"
import ContactSection from "../components/product/ContactSection"
import CallToAction from "../components/product/CallToAction"

const ProductPage = ({ data, pageContext }) => {
  const { allContentfulProductPage, allContentfulFooter } = data
  const langKey = pageContext.langKey

  const {
    pageName,
    productHero,
    clientTestimonials,
    clientOptions,
    clientLogos,
    seelksAmazonToolbox,
    callToAction,
  } = allContentfulProductPage.edges[0].node

  return (
    <>
      <Helmet>
        <title> Product </title>
        <meta name="description" content="" />
        <meta name="format-detection" content="telephone=no" />
        {langKey === "en" && <link rel="alternate" hreflang="en-us" />}
        {langKey === "fr" && <link rel="alternate" hreflang="fr" />}
      </Helmet>
      <Layout langKey={langKey} allContentfulFooter={allContentfulFooter}>
        <Container overrideCSS={{ pt: "128px" }}>
          <ProductHero langKey={langKey} productHero={productHero} />
          <ClientTestimonials clientTestimonials={clientTestimonials} />
          <WhatWeDo langKey={langKey} />
          <ClientOptions clientOptions={clientOptions} />
          <OurClients langKey={langKey} clientLogos={clientLogos} />
          <AmazonToolbox seelksAmazonToolbox={seelksAmazonToolbox} />
          <Strategies langKey={langKey} />
          <ContactSection langKey={langKey} />
          <CallToAction callToAction={callToAction} />
        </Container>
      </Layout>
    </>
  )
}

export default ProductPage

export const productPageQuery = graphql`
  query ProductPageFrQuery($langKey: String!) {
    allContentfulProductPage(filter: { node_locale: { eq: $langKey } }) {
      edges {
        node {
          pageName
          productHero {
            heroTitle
            heroSubtitle
            heroVideo {
              fluid(maxWidth: 800) {
                base64
                tracedSVG
                srcWebp
                srcSetWebp
              }
            }
          }
          clientTestimonials {
            personPhoto {
              fixed(width: 80) {
                width
                srcWebp
                srcSet
                srcSetWebp
                src
                height
                base64
                aspectRatio
              }
            }
            personName
            personPosition
            personTestimonial
          }
          clientOptions {
            clientOptionColor
            clientOptionIcon {
              fluid(maxWidth: 18) {
                srcWebp
                srcSet
                srcSetWebp
                src
                base64
                aspectRatio
              }
            }
            clientOptionName
            clientOptionTitle
            clientOptionSubtitle
            clientOptionImage {
              fluid(maxWidth: 900) {
                srcWebp
                srcSet
                srcSetWebp
                src
                base64
                aspectRatio
              }
            }
          }
          clientLogos {
            fixed(height: 40) {
              width
              srcWebp
              srcSet
              srcSetWebp
              src
              height
              base64
              aspectRatio
            }
          }
          seelksAmazonToolbox {
            toolkitIcon {
              fluid(maxWidth: 32) {
                srcWebp
                srcSet
                srcSetWebp
                src
                base64
                aspectRatio
              }
            }
            toolkitName
            toolkitDesscription {
              toolkitDesscription
            }
          }
          callToAction {
            ctaTitle
            ctaSubtitle
            ctaButtonText
            ctaImage {
              fluid(maxWidth: 900) {
                srcWebp
                srcSet
                srcSetWebp
                src
                base64
                aspectRatio
              }
            }
          }
        }
      }
    }
    allContentfulFooter(
      filter: { node_locale: { eq: $langKey }, agencyServices: { ne: null } }
    ) {
      edges {
        node {
          node_locale
          seelkOffices {
            officeCity
            officeAddress
            officeTelephoneNumber
          }
          saasForAmazon
          agencyServices
          aboutSeelk
          copyrights
          contact
          connect
        }
      }
    }
  }
`
