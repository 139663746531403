// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import { fontWeights } from "../../utils/theme/font-weights"

import ContactRedirection from "../contact-redirection"

const ProductHero = ({ langKey, productHero }) => {
  const { heroTitle, heroSubtitle, heroVideo } = productHero

  const productHeroQueryData = useStaticQuery(graphql`
    query ProductHeroQuery {
      productHeroBgImg: file(relativePath: { eq: "ProductHeroBg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1632) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <BackgroundImage
      fluid={productHeroQueryData.productHeroBgImg.childImageSharp.fluid}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          margin: "auto",
          maxWidth: "740px",
          pt: ["64px", "64px", "128px"],
          mb: ["64px", "64px", "128px"],
        }}
      >
        <h2
          sx={{
            color: "#100720",
            fontSize: ["22px", "36px", "48px"],
            fontWeight: fontWeights.extraBold,
            mb: ["16px", "32px", "32px"],
          }}
        >
          {heroTitle}
        </h2>
        <h3
          sx={{
            color: "#000000",
            opacity: 0.9,
            fontSize: ["15px", "15px", "24px"],
            fontWeight: fontWeights.normal,
            mb: "32px",
          }}
        >
          {heroSubtitle}
        </h3>
        <ContactRedirection
          href={
            langKey === "en"
              ? "https://www.jellyfish.com/en-us/contact-us"
              : "https://www.jellyfish.com/fr-fr/contact-us"
          }
          buttonContent={
            langKey === "fr" ? "ENTRER EN CONTACT" : "GET IN TOUCH"
          }
          overrideCSS={{
            width: "fit-content",
            alignSelf: "center",
            fontSize: ["12px", "12px", "16px", "16px"],
            px: "20px",
            mb: ["32px", "64px", "128px"],
          }}
        />
      </div>
    </BackgroundImage>
  )
}

export default ProductHero
