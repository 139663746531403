// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import { fontWeights } from "../../utils/theme/font-weights"
import strategies from "../../content/product/strategies.json"

import StrategyItems from "./components/StrategyItems"

const Strategies = ({ langKey }) => {
  const strategiesQueryData = useStaticQuery(graphql`
    query StrategiesQuery {
      productStrategiesBg: file(
        relativePath: { eq: "ProductStrategiesBg.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1632) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const productStrategiesBgImg =
    strategiesQueryData.productStrategiesBg.childImageSharp.fluid

  const strategyItemsData =
    langKey === "fr"
      ? strategies.fr.SaveTimeAndBuildStrategies
      : strategies.en.SaveTimeAndBuildStrategies

  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        mb: ["64px", "128px", "128px"],
      }}
    >
      <p
        sx={{
          color: "#100720",
          fontSize: ["22px", "32px", "48px"],
          fontWeight: fontWeights.extraBold,
          mb: ["32px", "64px", "64px"],
        }}
      >
        {langKey === "fr"
          ? "French title"
          : "Save time & build winning strategies"}
      </p>
      <BackgroundImage fluid={productStrategiesBgImg} sx={{ width: "100%" }}>
        <div
          sx={{
            width: "94%",
            maxWidth: "1316px",
            display: "grid",
            gridTemplateColumns: ["1fr", "1fr 1fr", "1fr 1fr 1fr 1fr"],
            gap: ["10%", "10%", "5%"],
            mx: "auto",
            py: ["64px"],
            pb: ["252px", "64px", "64px"],
          }}
        >
          {strategyItemsData.map((strategyItem, i) => (
            <StrategyItems key={i} strategyItem={strategyItem} />
          ))}
        </div>
      </BackgroundImage>
    </div>
  )
}

export default Strategies
