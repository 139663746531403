// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import Img from "gatsby-image"

import { fontWeights } from "../../utils/theme/font-weights"
import { colors } from "../../utils/theme/colors"

import NavigationLink from "../common/NavigationLink"

const CallToAction = ({ callToAction }) => {
  const { ctaTitle, ctaSubtitle, ctaButtonText, ctaImage } = callToAction

  return (
    <div
      sx={{
        display: "flex",
        flexDirection: ["column", "column", "row"],
        justifyContent: ["center", "center", "space-evenly"],
        alignItems: "center",
        backgroundColor: "#5898FF0A",
        borderRadius: "8px",
        py: ["64px", "64px", "0"],
      }}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: ["center", "center", "start"],
          textAlign: ["center", "center", "left"],
          width: ["100%", "88%", "37%"],
          mb: ["64px", "64px", "0"],
        }}
      >
        <p
          sx={{
            color: "#100720",
            fontSize: ["22px", "32px", "32px"],
            fontWeight: fontWeights.extraBold,
          }}
        >
          {ctaTitle}
        </p>
        <p
          sx={{
            color: "#100720",
            fontSize: ["15px", "20px", "24px"],
            fontWeight: fontWeights.normal,
          }}
        >
          {ctaSubtitle}
        </p>
        <NavigationLink
          content={ctaButtonText}
          navigateTo={"/amazon-agency/"}
          overrideCSS={{
            color: colors.white,
            backgroundColor: "#FFA41B",
            fontSize: ["12px", "14px", "16px"],
            fontWeight: fontWeights.bold,
            textTransform: "uppercase",
          }}
        />
      </div>
      <Img
        fluid={ctaImage.fluid}
        sx={{
          width: ["100%", "100%", "32%"],
          maxWidth: ["100%", "530px", "530px"],
        }}
      />
    </div>
  )
}

export default CallToAction
