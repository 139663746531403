// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"

import { fontWeights } from "../../../utils/theme/font-weights"

const Strategyitems = ({ strategyItem }) => {
  const { number, text } = strategyItem

  return (
    <div
      sx={{
        width: ["100%", "100%", "210px"],
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <p
        sx={{
          color: "#FFA41B",
          fontSize: ["60px", "80px", "80px"],
          fontWeight: fontWeights.extraBold,
          mb: ["16px", "32px", "32px"],
        }}
      >
        {number}
      </p>
      <p
        sx={{
          color: "#3C3C3C",
          fontSize: ["18px", "18px", "24px"],
          fontWeight: fontWeights.bold,
          mb: "0px",
        }}
      >
        {text}
      </p>
    </div>
  )
}

export default Strategyitems
