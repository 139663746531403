// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import Img from "gatsby-image"

import { fontWeights } from "../../../utils/theme/font-weights"

const ActiveTestimonial = ({ activeTestimonial }) => {
  const {
    personPhoto,
    personName,
    personPosition,
    personTestimonial,
  } = activeTestimonial

  return (
    <div
      sx={{
        width: ["62%", "62%", "55%"],
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        mx: "auto",
        pt: ["64px", "64px", "128px"],
        pb: "64px",
      }}
    >
      <div
        sx={{
          height: "80px",
          width: "80px",
          borderRadius: "50%",
          overflow: "hidden",
          mb: "16px",
          "& img": {
            objectFit: "contain",
          },
        }}
      >
        <Img alt="" fixed={personPhoto.fixed} />
      </div>
      <p
        sx={{
          color: "#100720",
          fontSize: "15px",
          fontWeight: fontWeights.semiBold,
          textTransform: "uppercase",
          mb: "8px",
        }}
      >
        {personName}
      </p>
      <p
        sx={{
          color: "#100720",
          fontSize: "15px",
          fontWeight: fontWeights.semiBold,
          opacity: 0.5,
          mb: "32px",
        }}
      >
        {personPosition}
      </p>
      <p
        sx={{
          color: "#100720",
          height: ["128px", "64px", "64px"],
          fontSize: ["15px", "18px", "24px"],
          fontWeight: fontWeights.body,
          opacity: 0.8,
          mb: "32px",
        }}
      >{`"${personTestimonial}"`}</p>
    </div>
  )
}

export default ActiveTestimonial
